<template>
  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="60px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="属性" prop="des" min-width="200px" />
    <el-table-column label="值" prop="val" min-width="200px" />
    <el-table-column
      fixed="right"
      label="操作"
      width="60">
      <template #default="scope">
        <el-button @click="showDialogForm({data: scope.row})" type="text" size="small">修改</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item label="关键字">
        <el-input v-model="dialog.form.key" readonly />
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="dialog.form.des" readonly />
      </el-form-item>
      <el-form-item
        label="值" prop="val"
        :rules="[
          { required: true, message: '值不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.val" :readonly="dialog.readonly" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      }
    };
  },
  created() {
    this.$api.getSystemConfigs().then(data => {
      this.tableData = data;
    });
  },
  mounted() {
  },
  methods: {
    showDialogForm({data, created = false, readonly = false}) {
      this.dialog.readonly = readonly;
      this.dialog.created = created;
      this.dialog.visibled = true;
      if (data) {
        this.dialog.form = { ...data };
      }

      let submit;
      if (created) {
        this.dialog.title = '创建';
        this.dialog.form = {};
      } else {
        this.dialog.title = '修改';
        submit = () => this.$api.updateSystemConfig(this.dialog.form);
      }

      if (readonly) {
        this.dialog.title = '详情';
        submit = () => false;
      }

      this.dialog.submit = () => this.validateForm(() => submit()
        .then(() => {
          this.$message.success('操作成功');
          this.dialog.visibled = false;
          this.queryTableData();
        })
        .catch(handleCatchStatus));
    },
    validateForm(successFn) {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          successFn();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dialogForm.resetFields();
    }
  }
};
</script>
